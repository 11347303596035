.container {
    margin: auto;
    width: max(36vmin, 36vmax);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: max(10vmin, 2vh);
    text-align: left;
}

.name {
    font-size: 36px;
    font-family: Iosevka;
    color:dimgray;
}

.title {
    font-size: 18px;
    font-weight: bold;
    /* padding-top: 58px; */
    font-family: Iosevka;
}

:global h2{
    font-size: 24px;
    font-weight: normal;
}

:global h3{
    font-size: 20px;
    font-weight: normal;
}

.textBlock {
    /* font-size: 16px; */
    /* width: max(400px, 70vmin); */
}

:global a {
    color: dimgray;
    text-decoration: none;
}

:global ul {
    list-style-type: circle;
    /* margin: 0; */
    /* padding: 0; */
    /* overflow: hidden; */
    text-decoration: none;
}

class book {
    font-style: normal;
}

blockquote {
    border-left: 3px solid #ccc;
    margin: 0;
    padding: 0.5em 10px;
    font-style:italic;
  }
  blockquote p {
    display: inline-block;
  }

blockquote em {
    font-style: normal;
}

.linksUl {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

:global li {
    display: list-item;
}

.linksLi {
    padding-right: max(50px, 10vmin);
    padding-bottom: 1vmin;
    /* list-style-type: none; */
    font-size: 16px;
    color:dimgray;
}

.linksLi a{
    text-decoration: none;
}

.linksLi a:hover{
    text-decoration: underline;
}

.pageLink {
    text-decoration: none;
}

.articleTitle {
    font-size: 28px
}

.articleSubtitle {
    font-size: 16px
}