.container {
    text-align: left;
    margin: auto;
    width: 36vmax;
    margin-top: max(5vmin, 2vh);
    font-family: Iosevka;
}

.navUl {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.navLi {
    text-decoration: none;
    float: left;
    padding-right: 2vw;
    font-size: 24px;
}
.navLi a:hover{
    text-decoration: underline;
}

.pageLink {
    text-decoration: none;
    font-size: 18px;
}