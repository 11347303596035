.container {
  text-align: center;
  /* margin-left: max(10vmin, 10vw); */
  margin-top: max(5vmin, 5vh);
  font-family: Iosevka;
}

.App-header {
  background-color: #FFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(50px + 2vmin);
  font-family: Iosevka;
  color: black;
}
